<template>
  <button
      type="button"
      class="mobileNavigationToggle"
      :class="{'is-active' : isActive}"
  >
    <span><span class="visuallyHidden">Menu</span></span>
  </button>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      required: true
    }
  },
}
</script>