<script setup>
import BaseModal from "@/components/BaseModal.vue";
import {getCookie, setCookie} from "@/helpers/helpers.js";
import {computed, ref} from "vue";

// Access props
const props = defineProps({
  url: {
    type: String,
    required: true
  },
  alt: {
    type: String,
    required: true
  },
  img: {
    type: String,
    required: true
  },
  cookieName: {
    type: String,
    required: true
  }
});

const isActive = ref(false);

// Wait to timeout and open modal
if (!getCookie(props.cookieName)) {
  // wait 2s
  setTimeout(() => {
    // open modal
    isActive.value = true;
  }, 2000);
}

/**
 * Handle close click
 * Set cookie
 * */
function handleClose() {
  // close modal
  isActive.value = false;

  // set cookie
  setCookie(props.cookieName, true, .25);
}

const isPropsValid = computed(() => {
  return props.url && props.alt && props.img;
});
</script>

<template>
  <div v-if="isPropsValid">
    <BaseModal
        id="popupCourse"
        :is-active="isActive"
        :size="800"
        @dtc-modal-close="handleClose"
    >
      <div class="popup">
        <a :href="props.url" target="_blank">
          <img 
            :src="props.img"
            :alt="props.alt"
            width="1200"
            height="628">
        </a>
      </div>
    </BaseModal>
  </div>
</template>
