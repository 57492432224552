<template>
  <MoreList title="Čtěte také" :items="items"/>
</template>

<script>
import MoreList from "@/components/MoreList.vue";
import MoreListItem from "@/components/MoreListItem.vue";

export default {
  components: {
    MoreList,
    MoreListItem
  },
  data() {
    return {
      items: []
    }
  },
  props: {
    active: {
      type: String,
      required: false
    }
  },
  created() {
    const data = [
      {
        key: 'ms365',
        title: 'Microsoft 365',
        description: 'Mít všechny důležité poznámky, dokumenty, prezentace, kontakty pohromadě není už jen sen.',
        url: '/microsoft365',
        image: 'microsoft365/microsoft365More/microsoft-365',
        isLocalImage: true,
      },
      {
        key: 'msAzure',
        title: 'Microsoft Azure',
        description: 'Data jsou všude kolem nás a jsou v nich uložené informace, které jsou jednou z nejcennějších komodit dnešní doby.',
        url: '/microsoft-azure',
        image: 'microsoft365/microsoft365More/microsoft-azure',
        isLocalImage: true,
      },
      {
        key: 'powerAutomate',
        title: 'Power Platform',
        description: 'Čas je vzácný. Obzvlášť v dnešní době, kdy se vše kolem nás zrychluje. Power Automate ti ho pomůže ušetřit.',
        url: '/power-automate',
        image: 'microsoft365/microsoft365More/power-automate',
        isLocalImage: true,
      },
      {
        key: 'cyberSecurity',
        title: 'Kyberbezpečnost',
        description: '"Zvyšování kybernetické bezpečnosti v kritických sektorech se stalo prioritou po celém světě,...',
        url: '/cybersecurity',
        image: 'microsoft365/microsoft365More/cyber-security',
        isLocalImage: true,
      },
    ]

    this.items = data.filter((row) => row.key !== this.active)
  },
}
</script>