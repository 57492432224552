<template>
  <nav
      :aria-label="translation('global_share-title')"
      :class="classString"
      class="socialShare"
  >
    <ul>
      <li>
        <a :href="shareLink('facebook')" :title="translation('global_share-facebook')" target="_blank">
          <svg width="28" height="29" viewBox="0 0 28 29" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.167 0H5.833A5.836 5.836 0 0 0 0 5.838v16.346a5.836 5.836 0 0 0 5.833 5.838h16.334A5.836 5.836 0 0 0 28 22.184V5.838A5.836 5.836 0 0 0 22.167 0Zm-3.5 8.173h-2.245c-.718 0-1.255.294-1.255 1.038v1.297h3.5l-.278 3.503h-3.222v9.34h-3.5v-9.34H9.333v-3.503h2.334V8.263c0-2.36 1.241-3.593 4.037-3.593h2.963v3.503Z" fill="#8ED1CC" fill-rule="nonzero"/>
          </svg>
        </a>
      </li>
      <li>
        <a :href="shareLink('linkedin')" :title="translation('global_share-linkedin')" target="_blank">
          <svg width="28" height="29" viewBox="0 0 28 29" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.333 22.207h-3.5v-6.544c0-3.932-4.666-3.634-4.666 0v6.544h-3.5V9.363h3.5v2.06c1.628-3.018 8.166-3.241 8.166 2.892v7.892ZM7.583 7.883a2.051 2.051 0 0 1-2.041-2.06c0-1.137.914-2.06 2.041-2.06 1.127 0 2.042.923 2.042 2.06a2.05 2.05 0 0 1-2.042 2.06Zm-1.75 14.324h3.5V9.363h-3.5v12.844ZM22.167.022H5.833A5.836 5.836 0 0 0 0 5.86v16.347a5.836 5.836 0 0 0 5.833 5.838h16.334A5.836 5.836 0 0 0 28 22.207V5.86A5.836 5.836 0 0 0 22.167.022Z" fill="#8ED1CC" fill-rule="evenodd"/>
          </svg>
        </a>
      </li>
      <li>
        <a :href="shareLink('twitter')" :title="translation('global_share-twitter')" target="_blank">
          <svg width="28" height="29" viewBox="0 0 28 29" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22.167.045H5.833A5.836 5.836 0 0 0 0 5.883v16.346a5.836 5.836 0 0 0 5.833 5.838h16.334A5.836 5.836 0 0 0 28 22.229V5.883A5.836 5.836 0 0 0 22.167.045Zm-.163 10.785c.244 5.39-3.773 11.401-10.885 11.401-2.162 0-4.175-.634-5.87-1.722a7.7 7.7 0 0 0 5.67-1.587 3.833 3.833 0 0 1-3.577-2.662c.6.115 1.191.08 1.729-.065-1.842-.37-3.113-2.03-3.072-3.806.516.287 1.107.46 1.734.48A3.842 3.842 0 0 1 6.547 7.75a10.87 10.87 0 0 0 7.894 4.005c-.559-2.397 1.26-4.706 3.732-4.706 1.1 0 2.097.465 2.794 1.211a7.677 7.677 0 0 0 2.434-.93 3.848 3.848 0 0 1-1.683 2.121 7.67 7.67 0 0 0 2.199-.604 7.773 7.773 0 0 1-1.913 1.982Z"
                fill="#8ED1CC" fill-rule="nonzero"/>
          </svg>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import {socialShare} from "@/helpers/helpers.js";
import codeBooksMixin from "@/mixins/codeBooksMixin.js";

export default {
  props: {
    className: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      required: true
    },
    moved: {
      type: Boolean,
      default: true
    },
  },
  methods: {
    shareLink(type) {
      return socialShare(type, location.href, this.title);
    }
  },
  computed: {
    classString(){
      let out = this.className;

      if(this.moved){
        out += ' socialShare--moved'
      }

      return out;
    }
  },
  mixins: [codeBooksMixin],
}
</script>