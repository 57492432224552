<template>
  <HighlightedSection
      :title="title"
      :text="text"
  >
    <BaseButton
        :href="url"
        :is-link="true"
        :full-width="true"
    >
      {{ link }}
    </BaseButton>
  </HighlightedSection>
</template>
<script>
import BaseButton from "@/components/BaseButton";
import HighlightedSection from "@/components/HighlightedSection";

export default {
  components: {BaseButton, HighlightedSection},
  props: {
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      default: null
    },
    link: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    }
  }
}
</script>