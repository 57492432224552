<template>
  <div class="userBar">
    <a
        v-if="!loggedIn"
        href="/login"
        class="userBar__login"
    >
      <span>{{ translation('auth_action-login') }}</span>
      <img
          src="../assets/images/icons/user.svg"
          alt=""
          class="icon"
          width="24"
          height="24"
          loading="lazy"
      >
    </a>
    <template v-else>
      <a
          v-if="userData"
          href="/dashboard"
          class="userBar__user"
      >
        {{ userData.firstname }} <span>{{ userData.lastname }}</span>
      </a>
      <LogoutButton
          class="userBar__logout"
          :title="translation('auth_action-logout')"
      >
        <img
            src="../assets/images/icons/logout.svg"
            :alt="translation('auth_action-logout')"
            class="icon"
            width="16"
            height="18"
            loading="lazy"
        >
      </LogoutButton>
    </template>
  </div>
</template>

<script>
import LogoutButton from "@/components/LogoutButton";
import codeBooksMixin from "@/mixins/codeBooksMixin";
import {mapGetters, mapState} from "vuex";

export default {
  components: {LogoutButton},
  computed: {
    ...mapGetters("user", ["loggedIn"]),
    ...mapState("user", {
      userData: (state) => state.userData,
    }),
  },
  mixins: [codeBooksMixin],
}
</script>