import {dateFormat, durationFormat, machineDateFormat, durationToHours} from "@/helpers/helpers";

export default {
    methods: {
        durationFormat(duration) {
            return durationFormat(duration);
        },
        dateFormat(date) {
            return dateFormat(date);
        },
        machineDateFormat(date) {
            return machineDateFormat(date);
        },
        durationToHours(duration) {
            return durationToHours(duration);
        },
    }
}