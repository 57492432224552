import _ from "lodash";

/**
 * Check if response has data, data is object or array
 * @param {object} response - endPoint
 * @return {boolean} - axios data
 */
function isApiResponseValid(response) {
    return response && response.data && (_.isPlainObject(response.data) || _.isArray(response.data));
}

/**
 * Convert duration in seconds to string {x}h {x}m {x}s
 * @param {number} duration - duration in seconds
 * @return {string} - formatted string
 */
function durationFormat(duration) {
    if (!duration) {
        return ''
    }

    let timeInSeconds = duration;
    const hours = Math.floor(timeInSeconds / 3600);
    timeInSeconds = timeInSeconds - hours * 3600;

    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds - (minutes * 60);

    return hours > 0 ? `${hours}h ${minutes}m ${seconds}s` : minutes > 0 ? `${minutes}m ${seconds}s` : `${seconds}s`
}

/**
 * Convert duration in seconds to string of hours
 * @param {number} duration - duration in seconds
 * @return {number} - duration in hours
 */
function durationToHours(duration) {
    if (!duration) {
        return 0
    }

    let timeInSeconds = duration;
    let hours = Math.floor(timeInSeconds / 3600);
    timeInSeconds = timeInSeconds - hours * 3600;

    const minutes = Math.floor(timeInSeconds / 60);

    if (minutes > 29) {
        hours += 1;
    }

    if (hours < 1) {
        hours = 1;
    }

    return hours;
}

/**
 * Convert UTC date string to
 * @param {Date} date - date string
 * @return {string} - formatted string
 */
function dateFormat(date) {
    let out;
    try {
        date = new Date(date);
        if (isNaN(date)) {
            throw new Error('invalid date');
        }
        out = date.toLocaleDateString("cz-CS")
        // todo change date string by locale
    } catch (err) {
        out = '';
    }

    return out;
}

/**
 * Convert UTC date string to YYYY-MM-DD foramt
 * @param {Date} date - date string
 * @return {string} - formatted string
 */
function machineDateFormat(date) {
    let out;
    try {
        date = new Date(date);
        if (isNaN(date)) {
            throw new Error('invalid date');
        }
        out = date.toISOString().split('T')[0]
        // todo change date string by locale
    } catch (err) {
        out = '';
    }

    return out;
}

/**
 * Create link to share url on social sites
 * @param {string} socialSite - date string
 * @param {string} url - url that you want share
 * @param {string} title - title (twitter wants title)
 * @return {string} - link
 */
function socialShare(socialSite, url, title) {
    let out;

    switch (socialSite) {
        case 'facebook':
            out = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
            break;
        case 'twitter':
            out = `https://twitter.com/intent/tweet?text=${encodeURIComponent(title + ' ' + url)}`;
            break;
        case 'linkedin':
            out = `https://www.linkedin.com/cws/share?mini=true&url=${encodeURIComponent(url)}`;
            break;
    }

    return out;
}

/**
 * Set cookie
 *
 * @param {string} name
 * @param {string} value
 * @param {number} days
 * */
function setCookie(name, value, days) {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

/**
 * Get cookie
 *
 * @param {string} name
 * */
function getCookie(name) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

/**
 * Remove cookie
 *
 * @param {string} name
 * */
function removeCookie(name) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export {
    isApiResponseValid,
    durationFormat,
    durationToHours,
    dateFormat,
    machineDateFormat,
    socialShare,
    setCookie,
    getCookie,
    removeCookie
}