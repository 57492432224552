import store from "@/store";

export default {
    methods: {
        getCodeBookByKey(key) {
            return store.getters["codebooks/getCodeBookByKey"](key);
        },
        getCodeBookByKeyAndValue(key, value) {
            return store.getters["codebooks/getCodeBookByKeyAndValue"](key, value);
        },
        translation(placeholder, attrs = null) {
            return store.getters["codebooks/getTranslation"](placeholder, attrs);
        },
    }
}