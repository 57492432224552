<template>
  <slot name="image"/>
  <div class="pageHeader" :class="`pageHeader--${headerClass}`">
    <div class="container">
      <div v-if="mpsv || type" class="pageHeader__params">
        <div class="pageHeader__params__param">{{ type }}</div>
        <div class="pageHeader__params__param">dotace od mpsv</div>
      </div>
      <div v-if="$slots.attributes" class="pageHeader__attributes">
        <slot name="attributes"/>
      </div>
      <h1>
        <slot name="default"/>
      </h1>
      <div v-if="$slots.subtitle" class="pageHeader__subtitle">
        <slot name="subtitle"/>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    props: {
        headerClass: {
          type: String,
          default: null,
          validator(value) {
            return typeof value === 'string' && value.trim().length > 0;
          }
        },
        mpsv: {
          type: Boolean,
          default: false
        },
        type: {
          type: String
        }
    },
}
</script>
