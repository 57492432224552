<template>
  <NavigationListItem
      :page="page"
      :url="url"
      :force-active="isPageActive"
      :is-open="hasChildren && isOpen"
      @navigationItemClick="handleClick"
  >
    <template #default>
      {{ translation(`header_nav_link-${page}`) }}
      <svg v-if="hasChildren" width="15" height="9" viewBox="0 0 15 9" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 1.834 1.876 0l5.625 5.4L13.126 0 15 1.834 7.501 9z" fill="currentColor" fill-rule="nonzero"/>
      </svg>
    </template>
    <template #after>
      <Transition :name="transitionName">
        <div v-if="hasChildren && isOpen" @click.self="handleClick">
          <ul :class="{'is-active': isOpen}">
            <template v-for="(item, key) in children" :key="key">
              <NavigationListItem
                  v-if="!item.requiresAuth || (item.requiresAuth && loggedIn)"
                  :page="key"
                  :title="item.title"
                  :url="item.url"
              >
                {{ item.title || translation(`header_nav_link-${key}`) }}
              </NavigationListItem>
            </template>
          </ul>
        </div>
      </Transition>
    </template>
  </NavigationListItem>
</template>

<script>
import NavigationListItem from "@/components/NavigationListItem";
import codeBooksMixin from "@/mixins/codeBooksMixin";
import {mapGetters, mapState} from 'vuex'

export default {
  data() {
    return {
      isOpen: false
    }
  },
  components: {NavigationListItem},
  emits: ['toggleNavigationListItem'],
  props: {
    page: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    },
    children: {
      type: [Object, Array],
      required: false
    },
    transitionName: {
      type: String,
      default: ''
    },
  },
  computed: {
    ...mapGetters("user", ["loggedIn"]),
    ...mapState("page", {
      currentPage: (state) => state.currentPage,
      pageName: (state) => state.page,
    }),
    /* Check if item is active or has some active children*/
    isPageActive() {
      let out = this.currentPage === this.page;

      // Check active children
      if (this.children && !out) {
        for (const item in this.children) {
          if (!out) {
            out = this.currentPage === item;
          }
        }
      }

      // activate articleList if page is articleDetail
      if(this.page === 'articleList' && this.pageName === 'articleDetail'){
        out = true;
      }

      // activate courses if page is video
      if(this.page === 'courses' && this.pageName === 'video'){
        out = true;
      }

      return out;
    },
    /* Check if item has some children available*/
    hasChildren() {
      let hasChildren = false;
      for (const key in this.children) {
        const item = this.children[key];
        if (!hasChildren) {
          hasChildren = !item.requiresAuth || (item.requiresAuth && this.loggedIn);
        }
      }

      return hasChildren;
    },
  },
  methods: {
    handleClick(e) {
      if (this.children) {
        e.preventDefault();
        this.isOpen = !this.isOpen;
        this.$emit('toggleNavigationListItem', this.isOpen)
      }
    }
  },
  mixins: [codeBooksMixin],
}
</script>