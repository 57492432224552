<template>
  <NavigationList
      class="profileNavigation"
      :label="'Profile'"
  >
    <NavigationListItem
        :url="'/dashboard'"
        :page="'dashboard'"
    >
      <img
          src="../assets/images/icons/dashboard.svg"
          alt=""
          class="icon"
          width="18"
          height="18"
          loading="lazy"
      >
      {{ translation('dashboard_page-title') }}
      <svg width="8" height="13" viewBox="0 0 5 8" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
        <path d="m0 1 1.019-1L5 4 1.019 8 0 7l3-3z" fill-rule="nonzero"/>
      </svg>
    </NavigationListItem>
    <NavigationListItem
        :url="'/profile'"
        :page="'profile'"
    >
      <img
          src="../assets/images/icons/edit.svg"
          alt=""
          class="icon"
          width="18"
          height="20"
          loading="lazy"
      >
      {{ translation('profile_page-title') }}
      <svg width="8" height="13" viewBox="0 0 5 8" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
        <path d="m0 1 1.019-1L5 4 1.019 8 0 7l3-3z" fill-rule="nonzero"/>
      </svg>
    </NavigationListItem>
  </NavigationList>
</template>

<script>
import NavigationList from "@/components/NavigationList";
import NavigationListItem from "@/components/NavigationListItem";
import CodeBooksMixin from "@/mixins/codeBooksMixin";
import {mapState} from 'vuex'

export default {
  components: {NavigationList, NavigationListItem},
  mixins: [CodeBooksMixin],
  computed: {
    ...mapState("page", {
      currentPage: (state) => state.currentPage,
    })
  }
}
</script>