<template>
    <div class="mpsvCard">
        <img src="../assets/images/logoMPSV.svg" alt="">
        <div class="mpsvCard__content">
            <h3>{{ title }}</h3>
            <p>{{ description }}</p>
            <div>
                <BaseButton :is-link="true" :href="`../blog/mpsv-dotace`">{{ buttonText }}</BaseButton>
            </div>
        </div>
    </div>
</template>
<script>
    import BaseButton from "@/components/BaseButton.vue";

    export default {
        props: {
            title: {
                type: String,
                required: true
            },
            description: {
                type: String,
                required: true
            },
            buttonText: {
                type: String,
                required: true
            }
        },
        components: {BaseButton}
    }
</script>