import {useStore} from "vuex";

export const codeBooksHelpers = () => {
    const store = useStore()

    const getCodeBookByKey = (key) => {
        return store.getters["codebooks/getCodeBookByKey"](key);
    };

    const getCodeBookByKeyAndValue = (key, value) => {
        return store.getters["codebooks/getCodeBookByKeyAndValue"](key, value);
    };

    const translation = (placeholder, attrs = null) => {
        return store.getters["codebooks/getTranslation"](placeholder, attrs);
    };

    return {
        getCodeBookByKey,
        getCodeBookByKeyAndValue,
        translation,
    }
}