<template>
  <template v-for="(item, key) in pages" :key="key">
    <TheHeaderNavigationListItem
        v-if="!item.requiresAuth || (item.requiresAuth && loggedIn)"
        :page="key"
        :url="item.url"
        :children="item.children"
        :transition-name="transitionName"
        @toggleNavigationListItem="$emit('toggleNavigationListItem', $event)"
    />
  </template>
</template>

<script>
import TheHeaderNavigationListItem from "@/components/TheHeaderNavigationListItem";
import {mapGetters, mapState} from "vuex";

export default {
  emits: ['toggleNavigationListItem'],
  props: {
    transitionName: {
      type: String,
      default: ''
    },
  },
  computed: {
    ...mapGetters("user", ["loggedIn"]),
    ...mapState("studyPrograms", {
      studyProgramsItems: (state) => state.studyPrograms,
    }),
    pages() {
      return {
        about: {
          url: '/about',
          requiresAuth: false,
          children: {
            aboutProject: {
              url: '/about',
              requiresAuth: false,
            },
            mentions: {
              url: '/napsali-o-nas',
              requiresAuth: false,
            },
          }
        },
        microsoft: {
          url: '/microsoft365',
          requiresAuth: false,
          children: {
            ms365: {
              url: '/microsoft365',
              requiresAuth: false,
            },
            msAzure: {
              url: '/microsoft-azure',
              requiresAuth: false,
            },
            powerAutomate: {
              url: '/power-automate',
              requiresAuth: false,
            },
            cyberSecurity: {
              url: '/cybersecurity',
              requiresAuth: false,
            },
          },
        },
        courses: {
          url: '/courses',
          requiresAuth: true,
        },
        certification: {
          url: '/blog/jak-ziskat-certifikat-od-microsoftu',
          requiresAuth: true,
        },
        studyPrograms: {
          url: '/',
          requiresAuth: false,
          children: this.studyPrograms
        },
        articleList: {
          url: '/blog',
          requiresAuth: false,
        },
        lectures: {
          url: '/trainings',
          requiresAuth: false,
        },
      }
    },
    studyPrograms() {
      let out = {};

      // Map api data to component data
      const items = this.studyProgramsItems.map(item => {
        return {
          id: item.id,
          title: item.title,
          url: '/studijni-program/' + item.url,
          requiresAuth: false
        }
      })

      // Create object with pageName with id as key for activate page in navigation
      items.forEach(item => {
        out[`studyProgram${item.id}`] = item;
      })

      return out;
    }
  },
  components: {
    TheHeaderNavigationListItem
  },
}
</script>