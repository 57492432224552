<template>
  {{ count }}
  <template v-if="count === 1">{{ translation('study_plan-length') }}</template>
  <template v-else-if="count > 1 && count < 5">{{ translation('study_plan-length-2-4') }}</template>
  <template v-else>{{ translation('study_plan-length-5-more') }}</template>
</template>

<script>
import codeBooksMixin from "@/mixins/codeBooksMixin";

export default {
  props: {
    count: {
      type: Number,
      required: true
    }
  },
  mixins: [codeBooksMixin],
}
</script>