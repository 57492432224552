<template>
  <nav
      :aria-label="label"
  >
    <ul>
      <slot/>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: null
    },
  },
}
</script>