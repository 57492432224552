<template>
  <nav
      v-if="path"
      class="theBreadcrumb"
      aria-label="Breadcrumb"
  >
    <div class="container container--xLg">
      <ol>
        <li>
          <a href="/">Hlavní stránka</a>
        </li>
        <li v-for="(item,index) in path">
          <a
              :href="item.url"
              :aria-current="index === path.length - 1 ? 'page' : null"
          >
            {{ item.title }}
          </a>
        </li>
      </ol>
    </div>
  </nav>
</template>
<script>
export default {
  props: {
    path: {
      type: Array,
      default: null
    }
  }
}
</script>