import { ref } from 'vue'
export function passwordRevealer () {
    // store current state
    let isPasswordRevealed = ref(false);

    // toggle password type
    const handlePasswordReveal = () => {
        isPasswordRevealed.value = !isPasswordRevealed.value;
    }

    return {
        isPasswordRevealed,
        handlePasswordReveal
    }
}