<template>
  <PageHeader>
    <template v-slot:image>
      <img
          src="../assets/images/microsoft365/pageHeader/header.jpg"
          srcset="
            ../assets/images/microsoft365/pageHeader/header.webp 576w,
            ../assets/images/microsoft365/pageHeader/header.jpg 576w,
            ../assets/images/microsoft365/pageHeader/header-lg.webp 992w,
            ../assets/images/microsoft365/pageHeader/header-lg.jpg 992w,
            ../assets/images/microsoft365/pageHeader/header-xxl.webp 1440w,
            ../assets/images/microsoft365/pageHeader/header-xxl.jpg 1440w,
            ../assets/images/microsoft365/pageHeader/header-hd.webp 1920w,
            ../assets/images/microsoft365/pageHeader/header-hd.jpg 1920w"
          sizes="100%"
          width="576"
          height="210"
          alt=""
          class="breadcrumbWrapper__image"
      >
    </template>
    <template v-slot:default>
      <slot/>
    </template>
  </PageHeader>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";

export default {
  components: {PageHeader}
}
</script>