<template>
  <form @submit="onSubmit" class="lectureForm">
      <!-- Last name -->
      <FormInput
        :label="translation('global_input-lastname-label')"
        :required="true"
        :placeholder="translation('global_input-lastname-placeholder')"
        :uuid="`lastname`"
        :error="lastnameError"
        v-model="lastname"
      />
      
      <!-- First name -->
      <FormInput
        :label="translation('global_input-firstname-label')"
        :required="true"
        :placeholder="translation('global_input-firstname-placeholder')"
        :uuid="`firstname`"
        :error="firstnameError"
        v-model="firstname"
      />
  
      <!-- Email -->
      <FormInput
        :label="translation('global_input-email-label')"
        :required="true"
        :type="'email'"
        :placeholder="translation('global_input-email-placeholder')"
        :uuid="`email`"
        :error="emailError"
        v-model="email"
      />
      
      <!-- Phone -->
      <FormInput
        :label="translation('global_input-phone-label')"
        :type="'tel'"
        :placeholder="translation('global_input-phone-placeholder')"
        :uuid="`phone`"
        :error="phoneError"
        v-model="phone"
      />
  
      <!-- comment -->
      <FormInput
        :label="translation('global_input-comment-label')"
        :placeholder="'Comment'"
        :uuid="translation('global_input-comment-label')"
        :error="commentError"
        :isTextarea="true"
        v-model="comment"
      />
  
      <!-- comment_up -->
      <FormInput
        :label="translation('global_input-comment-up-label')"
        :placeholder="'Commentup'"
        :uuid="translation('global_input-comment-up-label')"
        :error="commentupError"
        :isTextarea="true"
        v-model="commentup"
      />
  
      <!-- motivation -->
      <FormInput
        :label="translation('global_input-motivation-label')"
        :placeholder="'Motivation'"
        :uuid="translation('global_input-motivation-label')"
        :error="motivationError"
        :isTextarea="true"
        v-model="motivation"
      />
  
      <!-- highest_edu -->
      <FormInput
        :label="translation('global_input-highest-education-label')"
        :placeholder="'Highest Education'"
        :uuid="translation('global_input-highest-education-label')"
        :error="highesteduError"
        v-model="highestedu"
      />
  
      <!-- field -->
      <FormInput
        :label="translation('global_input-field-of-study-label')"
        :placeholder="'Field'"
        :uuid="translation('global_input-field-of-study-label')"
        :error="fieldError"
        v-model="field"
      />
  
      <!-- language_edu -->
      <FormInput
        :label="translation('global_input-language-edu-label')"
        :placeholder="'Language Education'"
        :uuid="translation('global_input-language-edu-label')"
        :error="languageeduError"
        v-model="languageedu"
      />
      
      <!-- mpsv_code -->
      <FormInput
        :label="translation('global_input-mpsv-code-label')"
        :placeholder="'MPSV Code'"
        :uuid="translation('global_input-mpsv-code-label')"
        :error="mpsvcodeError"
        v-model="mpsvcode"
      />

       <!-- reg_number_npo -->
       <FormInput
       :label="translation('global_input-reg-number-npo-label')"
       :placeholder="'Registration number NPO'"
       :uuid="translation('global_input-reg-number-npo-label')"
        :error="regnumbernpoError"
        v-model="regnumbernpo"
      />

       <!-- mpsv_number -->
       <FormInput
        :label="translation('global_input-mpsv-number-label')"
        :placeholder="'MPSV Number'"
        :uuid="translation('global_input-mpsv-number-label')"
        :error="mpsvnumberError"
        v-model="mpsvnumber"
        />
        
        <!-- candidates_address -->
        <FormInput
        :label="translation('global_input-candidates-address-label')"
        :placeholder="'Candidates address'"
        :uuid="translation('global_input-mpsv-url-label')"
        :error="candidatesaddressError"
        v-model="candidatesaddress"
        />

        <!-- mpsv_url -->
      <FormInput
        :label="translation('global_input-mpsv-url-label')"
        :placeholder="'MPSV URL'"
        :uuid="translation('global_input-mpsv-url-label')"
        :error="mpsvurlError"
        v-model="mpsvurl"
      />        
        
        <!-- origin -->
        <FormGroup :uuid="translation('global_input-origin-label')" :label="translation('global_input-origin-label')" :required="true" :error="originError">
          <template #icon v-if="$slots.icon">
            <slot name="icon"/>
          </template>
          <template #default>
            <div class="baseSelect">
              <select v-model="origin">
                <option v-for="origin in selectUserOriginOptions" :value="origin.id">{{ origin.value }}</option>
              </select>
              <svg width="15" height="9" viewBox="0 0 15 9" xmlns="http://www.w3.org/2000/svg" class="baseSelect__icon"><path d="M0 1.834 1.876 0l5.625 5.4L13.126 0 15 1.834 7.501 9z" fill-rule="nonzero"></path></svg>
            </div>
          </template>
          <template #button v-if="$slots.button">
            <slot name="button"/>
          </template>
        </FormGroup>
  
  
  
        <!-- Interest -->
        <FormGroup :uuid="translation('global_input-user-interest-status-labeluser-interest-status')" :label="translation('global_input-user-interest-status-label')" :required="true" :error="originError">
          <template #icon v-if="$slots.icon">
            <slot name="icon"/>
          </template>
          <template #default>
            <div class="baseSelect">
              <select v-model="intereststate">
                <option v-for="intereststate in selectInterestStateOptions" :value="intereststate.id">{{ intereststate.value }}</option>
              </select>
              <svg width="15" height="9" viewBox="0 0 15 9" xmlns="http://www.w3.org/2000/svg" class="baseSelect__icon"><path d="M0 1.834 1.876 0l5.625 5.4L13.126 0 15 1.834 7.501 9z" fill-rule="nonzero"></path></svg>
            </div>
          </template>
          <template #button v-if="$slots.button">
            <slot name="button"/>
          </template>
        </FormGroup>
   
      <div class="container container--xxxxs">
        <BaseAlertList :alerts="formAlerts" class="mb-4" />
        
        <!-- Button -->
        <BaseButton type="submit" :full-width="true" :isDisabled="isSubmitting">
          Přidat do kurzu
        </BaseButton>
      </div>
    </form>
  </template>
    
  <script>
  import BaseAlertList from "@/components/BaseAlertList";
  import BaseButton from "@/components/BaseButton";
  import FormInput from "@/components/FormInput";
  import BaseSelectBox from "@/components/BaseSelectBox.vue";
  import codeBooksMixin from "@/mixins/codeBooksMixin";
  import FormGroup from "@/components/FormGroup.vue";
  import { codeBooksHelpers } from "@/helpers/codeBooks.helper";
  import { BadRequestError, ValidationError } from "@/services/errors";
  import { lectureService } from "@/services/lectures.service";
  import { useField, useForm } from 'vee-validate';
  import { ref, computed } from 'vue';
  import { mapGetters, mapState, useStore } from "vuex";
  import * as yup from 'yup';
  
  export default {
    mixins: [codeBooksMixin],
    components: {
      BaseButton,
      FormInput,
      BaseAlertList,
      BaseSelectBox,
      FormGroup
    },
    props: {
      trainingId: {
        type: Number,
        required: true
      }
    },
    computed: {
      userorigin() {
        return this.getCodeBookByKey('user_origin');
      },
      selectUserOriginOptions() {
        return Object.keys(this.userorigin).map(key => {
          return {
            id: key,
            value: this.userorigin[key]
          };
        });
      },

      intereststates() {
        return this.getCodeBookByKey('user_interest_state');
      },
      selectInterestStateOptions() {
        return Object.keys(this.intereststates).map(key => {
          return {
            id: key,
            value: this.intereststates[key]
          };
        });
      }
    },

    setup(props) {
      const store = useStore(); // use the useStore hook to access the Vuex store
  
      // Access userData from Vuex state
      const userData = computed(() => store.state.user.userData);
      // form level alert variable
      let formAlerts = ref(null);
  
      let urlParams = ref(new URLSearchParams(window.location.search.substring(1)));


      // import translations
      const { translation } = codeBooksHelpers();
  
      // validator config
      const validationSchema = yup.object({
        firstname: yup
          .string()
          .required(() => translation('global_input-firstname-error'))
          .min(2, () => translation('global_validation-min_length', { number: 2 }))
          .max(80, () => translation('global_validation-max_length', { number: 80 })),
        lastname: yup
          .string()
          .required(() => translation('global_input-lastname-error'))
          .min(2, () => translation('global_validation-min_length', { number: 2 }))
          .max(30, () => translation('global_validation-max_length', { number: 30 })),
        email: yup
          .string()
          .required(() => translation('global_input-email-error'))
          .email(() => translation('global_validation-email_format'))
          .max(255, () => translation('global_validation-max_length', { number: 255 })),
        phone: yup
          .string()
          .max(30, () => translation('global_validation-max_length', { number: 30 }))
          .required(() => translation('global_input-phone-error')),
        comment: yup.string(),
        commentup: yup.string(),
        motivation: yup.string(),
        highestedu: yup.string(),
        field: yup.string(),
        languageedu: yup.string(),
        mpsvurl: yup.string(),
        origin: yup.number().integer(),
        intereststate: yup.number().integer(),
        mpsvcode: yup.string(),
        regnumbernpo: yup.string(),
        mpsvnumber: yup.string(),
        candidatesaddress: yup.string(),
      });
  
      // Creates a vee-validate form context
      const { handleSubmit, isSubmitting, resetForm } = useForm({
        validationSchema,
        initialValues: {
          firstname: '',
          lastname: '',
          email: '',
          phone: '',
          comment: '',
          commentup: '',
          motivation: '',
          highestedu: '',
          field: '',
          languageedu: '',
          mpsvurl: '',
          origin: 1,
          intereststate: 7,
          mpsvcode: '',
          regnumbernpo: '',
          mpsvnumber: '',
          candidatesaddress: ''
        }
      });
  
      // create a form fields with its validation state
      const { value: firstname, errorMessage: firstnameError } = useField('firstname');
      const { value: lastname, errorMessage: lastnameError } = useField('lastname');
      const { value: email, errorMessage: emailError } = useField('email');
      const { value: phone, errorMessage: phoneError } = useField('phone');
      const { value: comment, errorMessage: commentError } = useField('comment');
      const { value: commentup, errorMessage: commentupError } = useField('commentup');
      const { value: motivation, errorMessage: motivationError } = useField('motivation');
      const { value: highestedu, errorMessage: highesteduError } = useField('highestedu');
      const { value: field, errorMessage: fieldError } = useField('field');
      const { value: languageedu, errorMessage: languageeduError } = useField('languageedu');
      const { value: mpsvurl, errorMessage: mpsvurlError } = useField('mpsvurl');
      const { value: origin, errorMessage: originError } = useField('origin');
      const { value: intereststate, errorMessage: intereststateError } = useField('intereststate');
      const { value: mpsvcode, errorMessage: mpsvcodeError } = useField('mpsvcode');
      const { value: regnumbernpo, errorMessage: regnumbernpoError } = useField('regnumbernpo');
      const { value: mpsvnumber, errorMessage: mpsvnumberError } = useField('mpsvnumber');
      const { value: candidatesaddress, errorMessage: candidatesaddressError } = useField('candidatesaddress');
  
      // submit handler
      const onSubmit = handleSubmit(async (values) => {
        try {
          const makeSuccessMessage = (url) => {
            return `Uživatel byl úspěšně registrován.`;
          };
  
          // Send data to the API
          await lectureService.trainingEnrollmentDataAdmin(props.trainingId, values.firstname, values.lastname, values.email, values.phone, false, values.comment, values.commentup, values.motivation, values.highestedu, values.field, values.languageedu, values.mpsvurl, values.origin, values.intereststate, values.mpsvcode, values.regnumbernpo, values.mpsvnumber, values.candidatesaddress);
  
          // success message
          if (values.agree) {
            formAlerts.value = [{ 'message': makeSuccessMessage(props.mpsvUrl), type: "success" }];
          } else {
            formAlerts.value = [{ 'message': translation('training_form-alert-success'), type: "success" }];
          }
  
          // reset
          resetForm();
  
          // if agree open mpsv link
          if (values.agree) {
            setTimeout(() => {
              window.open(props.mpsvUrl, "_blank");
            }, 2000);
          }
        } catch (err) {
          if (err instanceof BadRequestError) {
            // set form error
            if (err.data === "E-mail already registered.") {
              formAlerts.value = [{ 'message': translation('training_form-user-exists'), type: "danger" }];
            } else {
              formAlerts.value = [{ 'message': translation('training_form-training-enrolled'), type: "danger" }];
            }
          } else if (err instanceof ValidationError) {
            // set form error
            formAlerts.value = [{ 'message': translation('registration_form-alert-validation_problem'), type: "danger" }];
          } else {
            formAlerts.value = [{ 'message': translation('global_alert-general'), type: "danger" }];
          }
        }
      });
  
      return {
        firstname,
        firstnameError,
        lastname,
        lastnameError,
        email,
        emailError,
        phone,
        phoneError,
        comment,
        commentError,
        commentup,
        commentupError,
        motivation,
        motivationError,
        highestedu,
        highesteduError,
        field,
        fieldError,
        languageedu,
        languageeduError,
        mpsvurl,
        mpsvurlError,
        origin,
        originError,
        intereststate,
        intereststateError,
        mpsvcode,
        mpsvcodeError,
        regnumbernpo,
        regnumbernpoError,
        mpsvnumber,
        mpsvnumberError,
        candidatesaddress,
        candidatesaddressError,
        onSubmit,
        isSubmitting,
        formAlerts,
        translation,
        FormGroup,
      };
    }
  };
  </script>
  