<template>
  <BaseLayout :breadcrumb="breadcrumb">
    <CenteredTitle
        v-if="userData"
        class="mb-6 mb-lg-9"
    >
      {{ userData.firstname }} {{ userData.lastname }}
    </CenteredTitle>

    <div class="container mb-6 mb-lg-9">
      <div class="profileLayout">
        <div>
          <ProfileNavigation/>
        </div>
        <div>
          <slot/>
        </div>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/components/BaseLayout.vue'
import CenteredTitle from "@/components/CenteredTitle";
import ProfileNavigation from '@/components/ProfileNavigation.vue'
import {mapState} from "vuex";

export default {
  props: {
    breadcrumb: {
      type: Array,
      default: null
    }
  },
  computed: {
    ...mapState("user", {
      userData: (state) => state.userData,
    }),
  },
  components: {
    CenteredTitle,
    BaseLayout,
    ProfileNavigation
  }
}
</script>
