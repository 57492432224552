<template>
  <div
    class="baseCollapse"
    :class="[
        `baseCollapse--${type}`,
        {'is-active' : isActive}
    ]"
  >
    <div class="baseCollapse__header">
      <button
        type="button"
        :id="id"
        :aria-expanded="isActive"
        :aria-controls="`baseCollapse-${id}`"
        @click.prevent.stop="handleClick"
      >
        <span class="baseCollapse__header-title" :id="`baseCollapse-${id}__heading`">
          <slot name="title"/>
        </span>
        <svg width="15" height="9" viewBox="0 0 15 9" xmlns="http://www.w3.org/2000/svg" fill="#D83A8E">
          <path d="M0 1.834 1.876 0l5.625 5.4L13.126 0 15 1.834 7.501 9z" fill-rule="nonzero"/>
        </svg>
      </button>
    </div>
    <SlideUpDown
      :model-value="isActive"
      @update:model-value="$emit('update:isActive', $event)"
      :duration="250"
      :id="`baseCollapse-${id}`"
      :aria-labelledby="`baseCollapse-${id}__heading`"
    >
      <div class="baseCollapse__body">
        <slot/>
      </div>
    </SlideUpDown>
  </div>
</template>

<script>
import SlideUpDown from "vue3-slide-up-down";

export default {
  emits: ["update:isActive", "baseCollapseToggle"],
  props: {
    id: {
      type: Number,
      required: true
    },
    isActive: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'whiteShadow',
      validator(value) {
        return ['whiteShadow', 'blueOutline'].includes(value)
      }
    }
  },
  methods: {
    handleClick() {
      this.$emit('update:isActive', !this.isActive);
      this.$emit('baseCollapseToggle')
    },
  },
  components: {
    SlideUpDown
  },
}
</script>
