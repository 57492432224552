<template>
  <article class="moreList__item">
    <a :href="url" :title="title"></a>
    <img
        v-if="isLocalImage"
        :src="require(`../assets/images/${image}.jpg`)"
        :srcset="require(`../assets/images/${image}.webp`) +  ' 200w'"
        alt=""
        width="200"
        height="130"
        loading="lazy"
    >
    <img
        v-else
        :src="image"
        alt=""
        width="200"
        height="130"
        loading="lazy"
    >
    <h3 class="h4">{{ title }}</h3>
    <p>{{ description }}</p>
  </article>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    },
    image: {
      type: String,
      required: true
    },
    isLocalImage: {
      type: Boolean,
      default: true
    },
  }
}
</script>