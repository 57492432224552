<template>
  <div class="microsoft365Study">
    <div class="container container--xs">
      <div class="microsoft365Study__header">
        <slot name="header"/>
      </div>
    </div>
    <div class="container container--xxs">
      <div class="microsoft365Study__body">
        <slot name="body"/>
      </div>
      <div v-if="$slots.more" class="microsoft365Study__more">
        <slot name="more"/>
      </div>
    </div>
    <div v-if="footerEnabled" class="microsoft365Study__footer">
      <div class="container container--xxs">
        <StudyBox :link="link">
          <p>Náročnost kurzu:
            <mark class="h3">
              <slot name="level">Začátečník</slot>
            </mark>
          </p>
        </StudyBox>
      </div>
    </div>
  </div>
</template>
<script>
import ActionButton from "@/components/ActionButton.vue";
import StudyBox from "@/components/StudyBox.vue";

export default {
  props: {
    link: {
      type: String,
      default: '/courses/uvodni-kurz'
    },
    footerEnabled: {
      type: Boolean,
      default: false
    },
  },
  components: {StudyBox, ActionButton},
}
</script>