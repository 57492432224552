<template>
    <div class="mpsvLayout">
      <div class="container container--xs">
        <h2 v-if="title">{{ title }}</h2>
        <slot/>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      title: {
        type: String
      }
    }
  }
  </script>