<template>
  <div class="microsoft365About" ref="content">
    <div class="container container--xs">
      <div class="microsoft365About__lead">
        <slot name="lead"/>
      </div>
      <div class="microsoft365About__text">
        <img
            :src="require(`../assets/images/microsoft365/microsoft365About/${image}.jpg`)"
            :srcset="require(`../assets/images/microsoft365/microsoft365About/${image}.webp`) +  ' 640w'"
            width="640"
            height="417"
            alt=""
            loading="lazy"
        >
        <slot name="default"/>
      </div>
    </div>
    <slot name="after"/>

    <img
        src="../assets/images/particles/laptop.svg"
        alt=""
        class="microsoft365About__particle microsoft365About__particle--0"
        width="348"
        height="290"
        loading="lazy"
    >
    <img
        src="../assets/images/particles/certificate.svg"
        alt=""
        class="microsoft365About__particle microsoft365About__particle--1"
        width="315"
        height="267"
        loading="lazy"
    >
    <img
        src="../assets/images/particles/briefcase.svg"
        alt=""
        class="microsoft365About__particle microsoft365About__particle--2"
        width="430"
        height="401"
        loading="lazy"
    >
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      required: true
    }
  }
}
</script>