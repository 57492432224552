<template>
  <li>
    <a
        :href="url"
        :title="title"
        :class="{
          'is-active': isPageActive,
          'is-open': isOpen,
        }"
        :aria-current="isPageActive ? 'page' : null"
        :target="openInNewWindow ? '_blank' : null"
        @click="$emit('navigationItemClick', $event)"
    >
      <slot/>
    </a>
    <slot name="after"/>
  </li>
</template>

<script>
import NavigationListItem from "@/components/NavigationListItem.vue";
import codeBooksMixin from "@/mixins/codeBooksMixin";
import {mapGetters, mapState} from 'vuex'

export default {
  emits: ['navigationItemClick'],
  components: {NavigationListItem},
  props: {
    url: {
      type: String,
      required: true
    },
    page: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    openInNewWindow: {
      type: Boolean,
      default: false
    },
    forceActive: {
      type: Boolean,
      default: false
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isPageActive() {
      return this.forceActive || this.currentPage === this.page;
    },
    ...mapState("page", {
      currentPage: (state) => state.currentPage,
    }),
  },
  mixins: [codeBooksMixin],
}
</script>