<template>
  <div v-if="items && items.length" class="moreList" :class="className" :style="style">
    <div class="moreList__header">
      <h2>{{ title }}</h2>
    </div>
    <div class="moreList__body">
      <ul>
        <li v-for="item in items">
          <MoreListItem
              :key="item"
              :title="item.title"
              :image="item.image"
              :url="item.url"
              :description="item.description"
              :is-local-image="item.isLocalImage"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import MoreListItem from "@/components/MoreListItem.vue";

export default {
  components: {MoreListItem},
  props: {
    withBackground: {
      type: Boolean,
      default: true
    },
    isLast: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: true
    },
    items: {
      type: Array,
      default: null
    },
  },
  computed: {
    className() {
      return {
        "moreList--withBackground": this.withBackground,
        "moreList--isLast": this.isLast,
      }
    },
    style() {
      return {
        "--items": this.items.length,
      }
    },
  },
}
</script>